<template>
  <div
    class="notice-message__wrapper"
    v-loading="isLoading"
    :element-loading-text="$t('loading')"
    element-loading-background="var(--report-analyse-mask)"
  >
    <p class="notice-message__title">{{$t('app.header.notice.mine')}}</p>
    <div class="notice-message__actions">
      <el-button type="primary" @click="handleRead" :disabled="!multipleSelection.length">{{ $t('notice.marked') }}</el-button>
      <el-button @click="deleDialogVisible = true" :disabled="!multipleSelection.length">{{ $t('notice.deleteMessage') }}</el-button>
    </div>
    <div class="notice-message__list">
      <el-table :data="tableData" @selection-change="handleSelectionChange" @filter-change="filterHandler" width="100%" :row-style="getRowStyle" @row-click="handleClick">
        <el-table-column type="selection" width="120" />
        <el-table-column prop="message_context" :label="$t('notice.messageTitle')">
          <template #default="scope">
            <div class="msg-context__wrap">
              <svg-icon name="a-xinxi-icon" class="not-read__icon" :class="{ isHide: scope.row.is_read === 1}"></svg-icon>
              <span :class="{ whiteColor: getWhiteColor( scope.row) }">{{ scope.row.message_content }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="time" :label="$t('notice.receiveTime')" width="200" />
        <el-table-column
          prop="type"
          :label="$t('notice.messageType')"
          width="180"
          :filter-multiple="false"
          filter-placement="bottom"
          :filters="[
            { text: $t('app.header.notice.system'), value: 'system' },
            { text: $t('app.header.notice.team'), value: 'team' }
          ]"
        />
      </el-table>
      <div class="pagination-wrap">
        <Pagination
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :totalSize="totalSize"
          :current_page="currentPage"
        />
      </div>
    </div>
    <el-dialog
      v-model="deleDialogVisible"
      :title="$t('label.attention')"
      width="470px"
    >
      <div class="delete-team__content">
        <svg-icon name="info"></svg-icon>
        <span style="line-height: 21px;">{{ $t('notice.deleteDesc') }}</span>
      </div>
      <template #footer>
        <span class="dialog-footer">

          <el-button type="primary" @click="deleDialogVisible = false">{{$t('dialog.cancel')}}</el-button>
          <el-button @click="handleDele"
            >{{ $t('action.confirm_remove') }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { onMounted, ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { getMessageList, deleteMessage, putMessage } from '@/services/notice.js'
import { message } from '@/hooks/useUI'
import { useState } from '@/hooks/useVuex'
import emitter from '@/app/emitter.js'
import dayjs from 'dayjs'
import Pagination from '@/components/common/Pagination'
import { t } from 'app/i18n'

const router = useRouter()
const { userInfo } = useState('user', ['userInfo'])
const tableData = ref([])
const multipleSelection = ref([])
const totalSize = ref(0)
const pageSize = ref(50)
const currentPage = ref(1)
const notice_type = ref([])
const deleDialogVisible = ref(false)

const isLoading = ref(false)
onMounted(() => {
  handleCurrentChange(1)
})

const getData = () => {
  isLoading.value = true
  getMessageList({
    page: currentPage.value,
    size: pageSize.value,
    message_type: notice_type.value && notice_type.value.length ? notice_type.value : undefined
  }).then(res => {
    isLoading.value = false
    multipleSelection.value = []
    tableData.value = res.messages.data.map(item => {
      return {
        ...item,
        time: dayjs(item.send_time).format('YYYY-MM-DD HH:mm:ss'),
        type: item.message_type === 0 ? t('app.header.notice.team') : t('app.header.notice.system')
      }
    })
    totalSize.value = res.messages.total
  })
}

const filterHandler = (type, ...arg) => {
  const selectType = Object.values(type)[0] && Object.values(type)[0].length ? Object.values(type)[0][0] : 'all'
  if (selectType === 'all') {
    notice_type.value = undefined
  } else if (selectType === 'system') {
    notice_type.value = [3]
  } else {
    notice_type.value = [0]
  }
  getData()
}

const handleSizeChange = (size) => {
  pageSize.value = size
  handleCurrentChange(1)
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getData()
}

const getWhiteColor = (row) => {
  if (multipleSelection.value && multipleSelection.value.length) {
    return multipleSelection.value.map(item => item.id).indexOf(row.id) > -1
  }
  return false
}

const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const getRowStyle = () => {
  return {
    cursor: 'pointer'
  }
}
const handleClick = (row) => {
  console.log(row)
  if (!row.is_read) {
    emitter.emit('reloadNotice')
    putMessage({
      message_ids: [row.id]
    })
  }
  // 团队消息，执行跳转
  if (row.message_type === 0) {
    if (row.ext_data.team_owner_id === userInfo.value.user_id) {
      // 发送方，反馈消息跳转到对应团队页面
      router.push({
        name: 'ownerteam',
        params: {
          team_id: toRaw(row).ext_data.team_id + ''
        }
      })
    } else {
      // 接收方，接受跳转到对应团队页面
      if (row.ext_data.invited_state === 2) {
        router.push({
          name: 'ownerteam',
          params: {
            team_id: toRaw(row).ext_data.team_id + ''
          }
        })
      } else {
        router.push({
          name: 'inviteteam'
        })
      }
    }
  }
  // 系统消息，提交错误，执行跳转
  if (row.message_type === 3) {
    router.push({
      name: 'err-hash'
    })
  }
  // 系统消息，升级通告
  if (row.message_type === 4) {
  }
}

const handleRead = () => {
  putMessage({
    message_ids: multipleSelection.value.map(item => item.id)
  }).then((res) => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      message(t('action.handle'))
      handleCurrentChange(1)
      emitter.emit('reloadNotice')
    }
  })
}
const handleDele = () => {
  deleteMessage({
    message_ids: multipleSelection.value.map(item => item.id)
  }).then((res) => {
    if ('code' in res) {
      message(res.message, 'error')
    } else {
      handleCurrentChange(1)
      message(t('action.delete'))
      deleDialogVisible.value = false
    }
  })
}
</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.notice-message__wrapper {
  padding: 20px 24px 30px;
  box-sizing: border-box;
  max-width: calc(100% - 1px);
  min-height: calc(100% - 112px);
  .notice-message__title {
    margin: 0;
    font-size: 18px;
    color: var(--color-text-1);
    line-height: 25px;
  }
  .notice-message__actions {
    padding: 15px 0;
  }
  .notice-message__list {
    min-height: calc(100vh - 305px);
    padding: 24px;
    background: var(--detect-config-bg);
    border-radius: 2px;
    :deep(.el-table__row) {
      height: 48px;
    }
    :deep(.el-table__column-filter-trigger) {
      margin-left: 7px;
    }
    :deep(.highlight.cell) {
      color: var(--color-text-1) !important;
      .el-table__column-filter-trigger > .el-icon {
        color: #00ab7a;
      }
    }
    .msg-context__wrap {
      transform: translateX(-12px);
      overflow: hidden;
      .whiteColor {
        color: var(--color-text-1);
      }
      &>span {
        float: left;
        max-width: calc(100% - 20px);
      }
    }
    .not-read__icon {
      color: #00ab7a;
      float: left;
      margin-right: 2px;
      margin-top: 5px;
      font-size: 13px;
      &.isHide {
        opacity: 0;
      }
    }
    :deep(.el-table__header) {
      height: 48px;
      .el-table__cell {
        background-color: var(--color-bg-3);
      }
    }
  }
  .pagination-wrap {
    margin-top: 15px;
    margin-right: -20px;
  }
}
</style>
<style lang="scss">
.el-table-filter {
  border: none !important;
  background-color: var(--detect-config-bg);
  .el-table-filter__list {
    padding: 6px 0;
    width: 76px;
    .el-table-filter__list-item {
      line-height: 32px;
      // width: 76px;
      color: var(--color-text-1);
      text-align: center;
      &.is-active {
        background-color: var(--color-bg-3);
      }
      &:hover {
        background-color: var(--color-bg-3);
      }
    }
  }
}
</style>
